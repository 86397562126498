import React from 'react';
import {Link} from 'gatsby';
import { Navbar, Nav} from 'react-bootstrap';


const Header = ({showMenu}) => {
  return (
    <Navbar fixed="top" expand="lg" className="border-bottom bk-white">
        <Navbar.Brand className="ps-3">
            <Link to="/">
                <img src="/logo-landing.svg" alt="Rosmino Ivess - Logo" />
            </Link>
        </Navbar.Brand>
        {
            showMenu && 
            <>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="pe-3"/>
                <Navbar.Collapse id="basic-navbar-nav" className='bk-white pe-lg-3'>
                    <Nav className="mx-auto">
                        <Nav.Link href="#products" className="text-center py-2 py-lg-0">Productos</Nav.Link>
                        <Nav.Link href="#dispenser" className="text-center py-2 py-lg-0">Servicio Frío-Calor</Nav.Link>
                        <Nav.Link href="#contact" className="text-center py-2 py-lg-0">Contactate</Nav.Link>
                        <Nav.Link href="#faq" className="text-center py-2 py-lg-0">Preguntas frecuentes</Nav.Link>
                    </Nav>
                    <Navbar.Text>
                        <a href="tel:08002201900" className="text-decoration-none d-flex py-3 py-lg-0 justify-content-center justify-content-lg-end align-items-center">
                            <img src="/icon-phone-landing.svg" className="me-2" alt="icon" />
                            <p className="text-cd-primary text-medium">0800-220-1900</p>
                        </a>
                    </Navbar.Text>
                </Navbar.Collapse>
            </>
        }
         
    </Navbar>   
  )
}


export default Header;