import React from 'react';


const Footer = () => {
  return (
    <footer className="footer">
        <div className="first-footer">
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                <img src="/logo-footer.svg" alt="Rosmino Ivess - Logo" />
                <div className="d-flex flex-column mt-2 mt-md-0">
                    <p className="text-uppercase fs-12 text-center text-md-end text-white mb-2">COMUNICATE CON NOSOTROS</p>
                    <a href="tel:08002201900" className="d-flex align-items-center justify-content-center justify-content-md-end" target="_blank" rel="noreferrer">
                        <img src="/icon-white-phone.svg" className="me-2" alt="icon" />
                        <p className="heading-3 text-white my-1">0800-220-1900</p>
                    </a>
                    <a href="http://wa.link/breru7 " className="d-flex align-items-center justify-content-center justify-content-md-end" target="_blank" rel="noreferrer">
                        <img src="/icon-white-wpp.svg" className="me-2" alt="icon" />
                        <p className="heading-3 text-white my-1">11-3926-7357</p>
                    </a>
                </div>
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-between mt-3">
                <div className="d-flex flex-column">
                    <a href="https://goo.gl/maps/ZVBEh3WXhAqkw1iA8" className="text-decoration-none" target="_blank" rel="noreferrer">
                        <p className="text-white text-center text-md-start text-regular">Lacroze 6019, Villa Ballester</p>
                        <p className="text-white mt-1 text-center text-md-start text-regular">Buenos Aires, Argentina</p>
                    </a>
                </div>
                <div className="d-flex flex-column mt-2 mt-md-0">
                    <p className="text-regular text-center text-md-end text-white">Lunes a viernes de 8 a 19hs</p>
                    <p className="text-regular text-center text-md-end mt-1 text-white">Sábados de 8 a 15hs</p>
                </div>
            </div>
        </div>
        <div className="second-footer d-flex flex-column flex-md-row justify-content-between align-items-center">
            <div className="d-flex flex-column">
                <p className="fs-14 text-center text-md-start text-white text-regular">Productos elaborados bajo normas IVESS.</p>
                <p className="fs-14 text-center text-md-start text-white mt-1 text-regular">ROSMINO Y CÍA S.A. 2022. Todos los derechos reservados.</p>
            </div>
            <p className="fs-14 text-regular text-center text-md-end text-white mt-2 mt-lg-0">Diseño y Desarrollo con ♥ <a href="https://creativedog.agency/" className="text-white text-decoration-none" target="_blank" rel="noreferrer">Creativedog Agency</a></p>
        </div>
    </footer>
  )
}


export default Footer;